import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { GalleryItem } from 'ng-gallery';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrl: './image-gallery.component.scss'
})
export class ImageGalleryComponent implements OnInit {

  @Input() imageInput!: GalleryItem[];
  @Input() id!: string;

  images: GalleryItem[] = [];

  constructor() {}

  ngOnInit(): void {
    this.images = this.imageInput
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['imageInput']) {
      this.images = changes['imageInput'].currentValue
    }
  }
}
