<div class="full-container">
    <div class="flex flex-wrap align-items-center justify-content-center">
        <div class="w-full">
            <div class="layout-wrapper layout-menu-static" [ngClass]="{'layout-menu-static-inactive': !displaySidebar}">
                <div class="top-bar" style="display: flex; justify-content: space-between; align-items: center;">
                    <!-- Burger -->
                    <div style="float: left; color: white;font-size: 9px;">
                        <i class="pi pi-home" style="font-size: 2rem; margin-top: 15px; margin-bottom: 15px; margin-left: 15px; cursor: pointer;" (click)="reloadPage()"></i>
                    </div>

                    <div class="flex pl-4" style="text-align: center;">
                        <div *ngIf="language == 'th'">
                            <button pButton type="button" label="เช็คห้องว่าง" class="booknow-topbar p-button-success p-button-sm" (click)="disPlayDialogBookingChanel()"></button>
                        </div>
                        <div *ngIf="language == 'en'">
                            <button pButton type="button" label="ROOMS AVAILABLE" class="booknow-topbar p-button-success p-button-sm" (click)="disPlayDialogBookingChanel()"></button>
                        </div>
                    </div>

                    <!-- Lang -->
                    <div class="flex justify-content-center sm:justify-content-end md:justify-content-end lg:justify-content-end md:px-2" style="float: right; margin-right: 15px;">
                        <div *ngIf="language == 'th'" style="color:white;">
                          <span (click)="changeLanguage('th')" style="margin-left: 3px; cursor: pointer; color: #1e8e42">TH</span> | <span (click)="changeLanguage('en')" style="margin-right: 3px; cursor: pointer;">EN</span>
                        </div>
          
                        <div *ngIf="language == 'en'" style="color:white;">
                            <span (click)="changeLanguage('th')" style="margin-left: 3px; cursor: pointer;">TH</span> | <span (click)="changeLanguage('en')" style="margin-right: 3px; cursor: pointer; color: #1e8e42">EN</span>
                        </div>
                      </div>
                </div>
            </div>
            <div class="card" style="margin-top: 62px;">
                <div class="content">
                    <div class="w-full header-hotel">
                        <div class="col-12 lg:col-12 content-roomdetail mb-2">
                            <app-image-gallery [imageInput]="images" [id]="'portal-gallery'"></app-image-gallery>
                        </div>
                        <div class="mt-2 separator-line"></div>
                    </div>

                    <div class="about-section" id="about-section">
                        <div class="grid grid-zero">
                            <div class="col-12 lg:col-12 mt-2 mb-2">
                                <div class="flex align-content-center flex-wrap map-section h-full max-h-full" [ngStyle]="{
                                    'background-image': 'url(img/map.png)',
                                    'background-size': 'cover',
                                    'background-repeat': 'repeat',
                                    'background-position': 'center center',
                                    'min-height': '265px'
                                    }">
                    
                                    <h1 style="color: #2c8344;" class="mx-4 mt-2" *ngIf="language == 'th'">{{ hotel?.hotel_name }}</h1>
                                    <h1 style="color: #2c8344;" class="mx-4 mt-2" *ngIf="language == 'en'">{{ hotel?.hotelname_eng }}</h1>
                    
                                    <div class="w-full">
                                        <p class="text-description mx-4">
                                            <span *ngIf="language == 'th'" [innerHTML]="hotel?.short_description"></span>
                                            <span *ngIf="language == 'en'" [innerHTML]="hotel?.eng_short_description"></span>
                                        </p>
                                    </div>
                                    <div class="w-full">
                                        <div class="location mx-4">
                                            <i class="fa fa-map-marker icon-color"></i>
                                            <span class="ml-2" *ngIf="language == 'th'">{{ hotel?.hotel_address }}</span>
                                            <span class="ml-2" *ngIf="language == 'en'">{{ hotel?.eng_address }}</span>
                                        </div>
                    
                                        <div class="tel mt-2 mx-4">
                                            <i class="fa fa-phone icon-color"></i> <span class="ml-2">{{ hotel?.hotel_tel }}</span>
                                        </div>
                    
                                        <div class="facebook mt-2 mx-4" *ngIf="hotel?.facebook && hotel?.facebook != ''">
                                            <i class="fa fa-facebook-square icon-color"></i>
                                            <span class="ml-2">
                                                <a href="{{ hotel?.facebook }}" target="_blank" class="a-open" *ngIf="language == 'th'">เปิดดู</a>
                                                <a href="{{ hotel?.facebook }}" target="_blank" class="a-open" *ngIf="language == 'en'">Open</a>
                                            </span>
                                        </div>
                    
                                        <div class="line mt-2 mx-4" *ngIf="hotel?.lineid && hotel.lineid != ''">
                                            <span style="vertical-align: middle;">
                                                <img alt="logo" src="img/line.png" style="vertical-align: start;"/>
                                                <span class="ml-2" style="vertical-align: start;">{{ hotel?.lineid }}</span>
                                            </span>
                                            
                                        </div>
                    
                                        <div class="start-price mt-2 mx-4">
                                            <i class="fa fa-tags icon-color"></i>
                                            <span class="ml-2" *ngIf="language == 'th'">ราคาเริ่มต้น: {{ hotel?.starting_price | number }} บาท</span>
                                            <span class="ml-2" *ngIf="language == 'en'">Lowest Price: {{ hotel?.starting_price | number }} Baht</span>
                                        </div>
                    
                                        <div class="action flex justify-content-around flex-wrap mt-2 mb-3">
                                        <button pButton type="button" *ngIf="language == 'th'" label="นโยบายที่พัก" class="p-button-success bt-policy" (click)="clickViewPolicy()"></button>
                                        <button pButton type="button" *ngIf="language == 'en'" label="Hotel Policy" class="p-button-success bt-policy" (click)="clickViewPolicy()"></button>
                                        <button pButton type="button" *ngIf="language == 'th'" label="ดูที่พักบนแผนที่" class="p-button-success bt-viewmap" (click)="clickViewMap()"></button>
                                        <button pButton type="button" *ngIf="language == 'en'" label="See Map" class="p-button-success bt-viewmap" (click)="clickViewMap()"></button>
                                    </div>
                    
                                    <div class="mt-2 separator-line"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="grid grid-zero mb-3">
                        <div class="col-12 lg:col-6" *ngIf="hotel?.data_services?.length != 0">
                            <div class="ourservice-section" *ngIf="hotel?.data_services?.length != 0" id="ourservice-section">
                                <h1 style="color: #2D2C2A; font-weight: bold;" class="mx-4 mt-2" *ngIf="language == 'th'">บริการเสริม</h1>
                                <h1 style="color: #2D2C2A; font-weight: bold;" class="mx-4 mt-2" *ngIf="language == 'en'">OUR SERVICE</h1>
                                <div class="mx-4">
                                    <splide [options]="{drag: 'free', rewind: true, fixedWidth : '150px', fixedHeight : '150px', gap : '1rem', pagination: false}">
                                        <splide-slide *ngFor="let service of hotel?.data_services">
                                            <img [src]="apiUrl + 'ADDITIONAL_SERVICE_' + hotel?.id + '/' + service?.image" alt="" width="150px" height="100px" class="service-image" id="{{ service.id }}">
                                            <div class="service-name" *ngIf="language == 'th'">{{ service?.service_name }}</div>
                                            <div class="service-name" *ngIf="language == 'en'">{{ service?.service_name_eng }}</div>
                                        </splide-slide>
                                    </splide>
                                </div>
                            </div>
                        </div>
                    
                        <div class="col-12 lg:col-6" *ngIf="hotel?.data_facility?.length != 0">
                            <div class="ourservice-section" *ngIf="hotel?.data_facility?.length != 0" id="facility-section">
                                <h1 style="color: #2D2C2A; font-weight: bold;" class="mx-4 lg:mx-0 mt-2" *ngIf="language == 'th'">สิ่งอำนวยความสะดวก</h1>
                                <h1 style="color: #2D2C2A; font-weight: bold;" class="mx-4 lg:mx-0 mt-2" *ngIf="language == 'en'">OUR FACILITIES</h1>
                    
                                <div class="mx-4 lg:mx-0" style="text-align: center !important;">
                                    <splide [options]="{drag : 'free', rewind: true, fixedWidth : '150px', fixedHeight : '150px', gap : '1rem', pagination: false}">
                                        <splide-slide *ngFor="let facility of hotel?.data_facility" class="text-center">
                                            <img [src]="apiUrl + 'ICON/' + facility?.icon" alt=""  width="75px" height="75px" class="icon-facilities">
                                            <div class="facility-name" *ngIf="language == 'th'">{{ facility?.icon_description }}</div>
                                            <div class="facility-name" *ngIf="language == 'en'">{{ facility?.icon_description_en }}</div>
                                        </splide-slide>
                                    </splide>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="copyright">
                        <div style="display: flex; align-items: center;justify-content: center;">
                            <img src="img/xpax-logo-radius.png" style="width: 60px;" class="mt-2"/>
                        </div>
                        <p style="font-size: 12px;" *ngIf="language == 'th'">Copyright © 2023 xPec Technology Co., LTD.<br>สงวนลิขสิทธิ์ตามกฏหมาย</p>
                        <p style="font-size: 12px;" *ngIf="language == 'en'">Copyright © 2023 xPec Technology Co., LTD.<br>All Right Reserved</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog [header]="language === 'th' ? 'นโยบายที่พัก' : 'Hotel Policy'" [(visible)]="displayPolicy" [modal]="true" [style]="{width: '90vw'}" [draggable]="false" [resizable]="false">
    <span *ngIf="language == 'th'" [innerHTML]="hotel?.policy"></span>
    <span *ngIf="language == 'en'" [innerHTML]="hotel?.eng_policy"></span>
    <div class="text-center" style="margin-top: 20px;">
        <button pButton type="button" [label]="language === 'th' ? 'ปิด' : 'Close'" class="p-button-success" style="font-size: 16px; font-weight: bold;" (click)="this.displayPolicy = false"></button>
    </div>
</p-dialog>

<p-dialog [(visible)]="dialogBookingChanel" [modal]="true" [style]="{width: '90vw'}" [breakpoints]="{'960px': '50vw', '640px': '90vw'}" [draggable]="false" [resizable]="false">
    <span *ngIf="language == 'th'">ดูผ่าน LINE สะดวก ปลอดภัย พร้อมแจ้งเตือนทุกข้อมูลการจองผ่าน LINE ได้แล้ววันนี้</span>
    <span *ngIf="language == 'en'">Effortless booking, Secure and Real-time notifications for all reservation details via LINE.</span>
    <div class="text-center mt-3">
        <button class="dialog-cus mr-4" type="button" pButton (click)="bookingLine()">
            <img alt="logo" src="img/LINE_APP_Android.png" style="width: 1.5rem"/>
            <span style="margin-left: 5px;" *ngIf="language == 'th'">ดูผ่านไลน์</span>
            <span style="margin-left: 5px;" *ngIf="language == 'en'">Via Line</span>
        </button>
        <button class="dialog-skip" type="button" pButton label="ดูผ่านเว็บ" (click)="bookingWeb()" *ngIf="language == 'th'"></button>
        <button class="dialog-skip" type="button" pButton label="Via Web" (click)="bookingWeb()" *ngIf="language == 'en'"></button>
    </div>
</p-dialog>





