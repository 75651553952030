import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';

import { GalleryModule } from 'ng-gallery';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { provideHttpClient, withFetch } from '@angular/common/http';

import { ButtonModule } from 'primeng/button';
import { PanelMenuModule } from 'primeng/panelmenu';
import { DialogModule } from 'primeng/dialog';

import { NgxSplideModule } from 'ngx-splide';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    ImageGalleryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    GalleryModule,
    ButtonModule,
    PanelMenuModule,
    NgxSplideModule,
    DialogModule
  ],
  providers: [
    provideHttpClient(withFetch()),
    provideClientHydration()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
